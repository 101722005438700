@font-face {
  font-family: "Dana";
  src: url(/public/B-NAZANIN.TTF);
}
@font-face {
  font-family: "MonaSans";
  src: url(/public/Mona-Sans.woff);
}

body {
  background-color: white;
  font-family: "MonaSans" "Dana";
}

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

input,textarea {
  outline: none;
}

.span {
  background: rgb(20, 20, 20);
  background: linear-gradient(
    0deg,
    rgba(20, 20, 20, 0.6139705882352942) 6%,
    rgba(255, 255, 255, 0) 100%
  );
}
.drop_down {
  position: absolute;
  top: 130%;
  width: 100px;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  flex-wrap: wrap;
  background-color: #111;
  overflow: hidden;
  z-index: 99999;
}

.enter-div:hover ul {
  opacity: 1;
  height: 320px;
}
.enter-div:hover div {
  opacity: 1;
  height: fit-content;
}
.enter-div:active .div-modal {
  opacity: 1;
  height: fit-content;
}

.chevron {
  transition: all 500ms;
}

.chevron-parent:hover .chevron {
  transform: rotate(180deg);
}

.chevron-modal {
  transition: all 500ms;
}

.chevron-parent-modal:focus .chevron-modal {
  transform: rotate(180deg);
}
.export {
  background-image: url("../public/img/Dark\ Background.jpeg");
  z-index: 40 !important;
}
.about-p {
  background-image: url("../public/img/image.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}
@media screen and (min-width: 1024px) {
  /* .about-p::before {
    content: "";
    background-color: #000;
    background-size: cover;
    position: absolute;
    z-index: 1;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.65;
  } */
}

.learn-bg {
  background-image: url("../public/img/background2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.filter-open:focus .filter {
  left: 0%;
}
.filter-close:focus .filter {
  left: -100%;
}
.paginationbttn {
  width: fit-content;
  height: 40px;
  display: flex;
  justify-content: center;
  padding: 10px;
  margin: 20px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  font-size: 15px;
}
.paginationbttn a {
  padding: 8px;
  padding-left: 13px;
  padding-right: 13px;
  margin: 8px;
  background-color: #eee;
  border-radius: 100%;
}
.paginationbttn a:hover {
  background-color: rgb(57, 117, 220);
  color: white;
  transition-duration: 0.3s;
}
.activebttn a {
  background-color: rgb(57, 117, 220);
  color: white;
  transition-duration: 0.3s;
}
.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}
.image-gallery-thumbnail.active {
  opacity: 1;
  border: none !important;
}
.image-gallery-thumbnail:hover {
  opacity: 1;
  border: none !important;
}
.image-gallery-thumbnail {
  opacity: 0.5;
}

.VIpgJd-ZVi9od-l4eHX-hSRGPd {
  display: none;
}
.VIpgJd-ZVi9od-ORHb-OEVmcd {
  visibility: hidden !important;
}
.goog-te-banner-frame {
  display: none;
  height: 0 !important;
  visibility: hidden;
}
.goog-te-combo {
  color: #000 !important;
  font-size: 15px !important;
  outline: none !important;
}

.magni {
  margin: 60px;
  border: 1px solid gray;
  filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.2))
    drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));
  object-fit: cover;
}
.magniimg {
  max-width: none !important;
  max-height: none !important;
}
.scroll-cate::-webkit-scrollbar {
  height: 8px;
}

.scroll-cate::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 5px;
}
.scroll-cate::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 5px;
}
.border-sec {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
}
.border-sec::before {
  content: "";
  position: absolute;
  background-color: gray;
  width: 15%;
  height: 3px;
  bottom: 35%;
  right:  5%;
  
}
.border-sec::after {
  content: "";
  position: absolute;
  background-color: gray;
  width: 15%;
  height: 3px;
  bottom: 35%;
  left: 5%;
  
}
.swiper-wrapper{
  align-items: center;
}
@media screen and (max-width: 600px) {
  .mySwiper {
    margin-top: 1rem;
  }
  .magni {
    display: none;
  }
  .magniimg {
    display: none;
  }
  .magniprop ~ div {
    display: none !important;
  }
  .goog-te-combo {
    font-size: 25px !important;
    background-color: transparent !important;
    color: #ffffff !important;
    font-weight: 400 !important;

    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  }
  .goog-te-combo option {
    font-size: 10px !important;
    color: #000 !important;
    width: 30px !important;
  }
}

@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap);
